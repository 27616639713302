import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import Spacer from "../../components/Layout/Spacer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import BaseText from "../../components/Text/BaseText";
import "./Roadmap.css";
export default function RoadMap() {
  return (
    <MainSectionContainer
      backgroundColor="#222"
      height={"auto"}
      paddingHorizontal="10vw"
      id="Roadmap"
    >
      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
      >
        Roadmap
      </BaseText>
      <Spacer height="40px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="center">
          <div className="road-map-node completed"></div>
          <div className="road-map-line" style={{ height: "250px" }}></div>
          <div className="road-map-node in-progress"></div>
          <div className="road-map-line" style={{ height: "400px" }}></div>
          <div className="road-map-node"></div>
          <div className="road-map-line" style={{ height: "270px" }}></div>
          <div className="road-map-node"></div>
          <div className="road-map-line" style={{ height: "190px" }}></div>
          <div className="road-map-node"></div>{" "}
          <div
            className="road-map-dotted-line"
            style={{ height: "250px" }}
          ></div>
        </VerticalFlexContainer>
        <Spacer width="20px" />

        <VerticalFlexContainer>
          <Spacer height="40px" />
          <div className="road-map-description">
            <BaseText color="#22ffff" textAlign="left" fontWeight="bold">
              Q1 - INCEPTION
            </BaseText>
            <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
              This is where it all began. When Asgard was in a time of need, its
              lesser known brother, Assgard came to the rescue.
            </BaseText>
          </div>
          <Spacer height="105px" />
          <div className="road-map-description">
            <BaseText color="#22ffff" textAlign="left" fontWeight="bold">
              Q2-1 VISION
            </BaseText>
            <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
              This is where discussions for the use case are happening in the
              community. The process of deciding how this magnificent token will
              be used. All options will be considered and the winner shall take
              over Assgard.
              <br />
              <br />
              In parallel, growing the community will be important and we will
              work with the community, influencers and social media to make sure
              our message spreads to a wider audience.
            </BaseText>
          </div>

          <Spacer height="110px" />
          <div className="road-map-description">
            <BaseText color="#22ffff" textAlign="left" fontWeight="bold">
              Q2-2 EXECUTION
            </BaseText>
            <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
              This is when we will start execution of the ideas and projects
              decided by the community. We will continue to listen and mould by
              maintaining an open channel of communication. Our whitepaper will
              also be written during this time as the vision will be defined.
            </BaseText>
          </div>
          <Spacer height="100px" />
          <div className="road-map-description">
            <BaseText color="#22ffff" textAlign="left" fontWeight="bold">
              Q3 - PERFECTION
            </BaseText>
            <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
              Feedback will be the main force in this quarter. We will
              communicate closely with the community to polish the product of
              all our work together.
            </BaseText>
          </div>
          <Spacer height="90px" />
          <div className="road-map-description">
            <BaseText color="#22ffff" textAlign="left" fontWeight="bold">
              BEYOND - REPETITION
            </BaseText>
            <BaseText color="#DEDEDE" fontSize="16px" textAlign="left">
              We don't plan to stop once perfection has been achieved. We
              believe that by this point, our community will have gained enough
              volume and confidence to start over again and expand our vision.
            </BaseText>
          </div>
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
    </MainSectionContainer>
  );
}
