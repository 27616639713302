import { useEffect, useState } from "react";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import BaseText from "../../components/Text/BaseText";
import FeatureSelector from "./FeatureSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faFlask,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import Spacer from "../../components/Layout/Spacer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";

const options = {
  "Community driven": {
    title: "Community driven",
    description: (
      <div className="description-container">
        <h2 className="token-title">The Yggdrasil</h2>
        <p className="token-text">
          The Yggdrasil, the tree of life, as it gave life to everything else,
          it also gave life to Assgard token. It was given to the community as a
          humble gift by its creator and left it on our hands to mould it into
          its greatness. It might be the utility for something bigger, a
          wholesome meme or the next big thing in the Binance Smart Chain.
        </p>
        <p className="token-text">
          One thing is certain, our community are passionate and talented enough
          to achieve anything we set our mind to. We also have people from
          around the globe which all contribute and help us look at the bigger
          picture.
        </p>
        <p className="token-text">Join up and have a say!</p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faUsers} size="3x" />,
  },
  Tokenomics: {
    title: "Tokenomics",
    description: (
      <div className="description-container">
        <h2 className="token-title">8% Automatic LP ♻️</h2>
        <p className="token-text">
          Every trade contributes towards automatically generating liquidity
          locked inside PancakeSwap LP, this adds value to the token as the
          community grows. The value the creator chose is 8% for this LP
          contribution.
        </p>
        <h2 className="token-title">2% Redistribution 💎</h2>
        <p className="token-text">
          Holders earn passive rewards through static reflection as they watch
          their balance of Assgard token grow indefinitely. 2% of every trade
          will be distibuted accross all holders.
        </p>
        <h2 className="token-title">Project underway 🙌</h2>
        <p className="token-text">
          As a community we will decide what the utility of this token is. We
          already have several proposals for real life projects, such as the
          profit tracker. A team has already formed and is ready to take up the
          project.
        </p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faPiggyBank} size="3x" />,
  },

  Features: {
    title: "Features",
    description: (
      <div className="description-container">
        <h2 className="token-title">🔥 LP Burned 🔥</h2>
        <p className="token-text">
          The LP is burned. You don't have to trust our word for it, check
          the&nbsp;
          <a
            target="_blank"
            href="https://bscscan.com/tx/0x5d57c6110b8c38d6a1bbff78a8948a3cd2e160c1f71e24d27e450ea8fb483254"
          >
            Bscscan transaction
          </a>
          &nbsp;out for yourself!
        </p>

        <h2 className="token-title">👌🏻 Ownership Renounced 👌🏻</h2>
        <p className="token-text">
          The owner left Assgard in the hands of its citizens. Once an
          assgardian, always an assgardian. As we believe in making your own
          research, here's the&nbsp;
          <a
            target="_blank"
            href="https://bscscan.com/tx/0x372078ef0c811cc9fcdbbe89b1dde3dfb8510bdb4e6e190a37bc388767ac5004"
          >
            transaction
          </a>
          .
        </p>
        <h2 className="token-title">🔰Stealth Launch 🔰</h2>
        <p className="token-text">
          No-one saw it coming. The token was released completely undercover and
          left in the fate of the bums of the gods.
        </p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faFlask} size="3x" />,
  },
};

export default function Token() {
  const [option, setOption] = useState("Tokenomics");

  return (
    <MainSectionContainer
      paddingHorizontal="10vw"
      backgroundColor="#222"
      height="auto"
      width="100%"
      id="Token"
    >
      <Spacer height="40px" />
      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
      >
        Token ($ASSG)
      </BaseText>
      <Spacer height="12px" />
      <h3 fontSize="24px" id="token-address">
        0x9b2411dd7002aef8e27c436ea0f0a75c640361f7
      </h3>
      <Spacer height="40px" />
      <FeatureSelector
        options={Object.keys(options)}
        setOption={setOption}
        currentOption={option}
      />
      <Spacer height="32px" />
      <HorizontalFlexContainer width="100%" flexWrap="wrap">
        <VerticalFlexContainer flex={1} justify="center" align="center">
          <Spacer flex={1} />
          <div className="icon-container">{options[option].icon}</div>
          <Spacer flex={3} />
        </VerticalFlexContainer>
        <HorizontalFlexContainer flex={1} height="600px">
          {options[option].description}
        </HorizontalFlexContainer>
      </HorizontalFlexContainer>
    </MainSectionContainer>
  );
}
